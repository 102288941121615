import { API_URL } from "config";
import Keycloak, {
  KeycloakError,
  KeycloakInstance,
  KeycloakProfile,
  KeycloakPromise,
} from "keycloak-js";
import { persistor } from "./apolloClient";
import {
  getLastUsedLinkedContact,
  isStandalone,
  setLastUsedLinkedContact,
} from "./pwa";

const FA_USER_REALM_ROLE = "FA_ADMIN";

const getSsoRedirectUri = () => {
  const url = new URL(
    `${process.env.PUBLIC_URL}/keycloak-silent-check-sso.html`,
    window.location.origin
  );
  return url.href;
};

const keycloakInitConfig = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: getSsoRedirectUri(),
  pkceMethod: "S256",
} as const;

export interface Access {
  sell: boolean;
  buy: boolean;
  withdraw: boolean;
  deposit: boolean;
  impersonate: boolean;
  cancelOrder: boolean;
  switch: boolean;
  advisor: boolean;
}

type FAKeycloakProfile = KeycloakProfile & {
  attributes?: {
    linked_contact?: string[];
  };
};

type FAKeycloakInstance = Omit<
  KeycloakInstance,
  "profile" | "loadUserProfile"
> & {
  profile?: FAKeycloakProfile;
  loadUserProfile(): KeycloakPromise<FAKeycloakProfile, void>;
};

type SubscribeFunctionType = (state: KeycloakServiceStateType) => void;

export interface KeycloakServiceStateType {
  initialized: boolean;
  authenticated: boolean;
  error?: boolean;
  /**
   * The linked contact the app applies.
   * This can be the user's linked contact OR an impersonated contact.
   */
  linkedContact: string | undefined;
  /**
   * This is the contact that the user is linked to.
   */
  trueLinkedContact: string | undefined;
  userProfile: KeycloakProfile | undefined;
  access: Access;
}

export const keycloakServiceInitialState: KeycloakServiceStateType = {
  initialized: false,
  authenticated: false,
  linkedContact: undefined,
  trueLinkedContact: undefined,
  userProfile: undefined,
  error: undefined,
  access: {
    sell: false,
    buy: false,
    withdraw: false,
    deposit: false,
    impersonate: false,
    cancelOrder: false,
    switch: false,
    advisor: false,
  },
};

class KeycloakService {
  keycloak: FAKeycloakInstance;
  state: KeycloakServiceStateType = keycloakServiceInitialState;
  subscribeFunction: SubscribeFunctionType | undefined;

  private inactivityTimeout = 5 * 60 * 1000; // 5 minutes
  private inactivityTimer: number | undefined;

  private countdownSeconds = 300; // 5 minutes in seconds
  private countdownInterval: number | undefined;

  constructor(instance: FAKeycloakInstance) {
    this.keycloak = instance;
    this.init();
    this.setupInactivityListeners();
  }

  initOffline() {
    const lastUsedLinkedContact = getLastUsedLinkedContact();
    if (isStandalone && lastUsedLinkedContact) {
      this.state = {
        ...this.state,
        initialized: true,
        authenticated: true,
        linkedContact: lastUsedLinkedContact,
      };
    } else {
      this.state = {
        ...this.state,
        error: true,
      };
    }
    this.updateState();
    const initWhenReconnect = () => {
      this.init();
      window.removeEventListener("online", initWhenReconnect);
    };
    window.addEventListener("online", initWhenReconnect);
    // logout logic
    this.setupInactivityListeners();
  }

  init() {
    if (!window.navigator.onLine) {
      this.initOffline();
      return;
    }
    this.keycloak.init(keycloakInitConfig).catch((error) => {
      console.error(error);
      this.initOffline();
    });

    this.keycloak.onReady = this.onReady;
    this.keycloak.onAuthError = this.onError;
    this.keycloak.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
    this.keycloak.onAuthRefreshError = this.onError;
    this.keycloak.onAuthLogout = this.onAuthLogout;
    this.keycloak.onTokenExpired = this.onTokenExpired;
  }

  subscribe(subscribeFunction: SubscribeFunctionType) {
    this.subscribeFunction = subscribeFunction;
  }

  unsubscribe() {
    this.subscribeFunction = undefined;
  }

  notifyStateChanged() {
    this.subscribeFunction?.(this.state);
  }

  onError = (errorData?: KeycloakError) => {
    console.error(errorData);
  };

  onTokenExpired = async () => {
    await this.keycloak.updateToken(5);
  };

  onAuthRefreshSuccess = async () => {
    this.updateState();
  };

  onAuthLogout = async () => {
    this.removeInactivityListeners();
    this.state = {
      ...keycloakServiceInitialState,
    };
    this.updateState();
    await this.keycloak.logout();
  };

  onReady = async (authenticated: boolean) => {
    if (!authenticated) {
      // Redirect to login page
      this.keycloak.login();
    } else {
      try {
        this.state = {
          ...this.state,
          initialized: true,
          authenticated: authenticated,
          error: false,
          access: await this.deriveAccess(),
        };

        await this.updateLinkedContact();

        this.updateState();
      } catch (error) {
        console.error(error);
        await this.onAuthLogout(); // Logout
      }
    }
  };

  async updateLinkedContact() {
    if (this.state.authenticated) {
      const profile = await this.keycloak.loadUserProfile();
      const linkedContact = await this.getContactIdFromQuery();
      if (linkedContact !== this.state.linkedContact) {
        const lastUsedLinkedContact = getLastUsedLinkedContact();
        if (linkedContact !== lastUsedLinkedContact) {
          // Clear Apollo's local storage cache to make sure that different contacts' data won't mix
          await persistor.purge();
        }
        setLastUsedLinkedContact(linkedContact);
        this.state = {
          ...this.state,
          linkedContact: linkedContact,
          trueLinkedContact: linkedContact,
          userProfile: profile,
        };
      }
    }
    // // Clear Apollo's local storage cache
    // await persistor.purge();
  }

  getLinkedContactFromProfile(profile: FAKeycloakProfile) {
    return profile?.attributes?.linked_contact?.[0];
  }

  getUserProfile(profile: FAKeycloakProfile) {
    return profile?.attributes?.linked_contact?.[0];
  }

  updateState() {
    this.notifyStateChanged();
  }

  /**
   * Gets the /keycloak.json.
   * @returns parsed keycloak.json.
   */
  async getConfigFile() {
    try {
      const config = await fetch(`${process.env.PUBLIC_URL}/keycloak.json`);
      const parsedConfig = await config?.json();
      return parsedConfig;
    } catch (error) {
      console.error("Failed to get keycloak.json.");
    }
  }

  async getToken() {
    await this.keycloak.updateToken(1);
    return this.keycloak.token;
  }

  async getContactIdFromQuery() {
    try {
      const response = await fetch(`${API_URL}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.getToken()}`,
        },
        mode: "cors",
        body: JSON.stringify({
          query: `
            query GetContactId{
              contact{
                id,
                externalId
              }
            }
          `,
        }),
      });

      const parsedResponse = await response.json();
      return parsedResponse?.data?.contact?.id;
    } catch {
      console.error(`Error getting contact id.`);
    }
  }

  /**
   * Checks whether the user has at least one of the role(s) specified in an entry in the keycloak.json.
   * @param key the key in the keycloak.json to check for roles.
   * @returns true if user has at least one specified roles.
   */
  async hasAnyRole(key: string) {
    try {
      const keycloakJson = await this.getConfigFile();
      // Optional field
      const configuredRoles = keycloakJson?.[key] as
        | Record<string, string[]>
        | undefined;

      if (configuredRoles) {
        return Object.entries(configuredRoles)?.some(
          ([keycloakClient, writeRoles]) => {
            return writeRoles?.some((role) =>
              this.keycloak.hasResourceRole(role, keycloakClient)
            );
          }
        );
      }
    } catch (error) {
      console.error("Unable to determine write access rights. Check if keycloak.json is properly configured. Defaulting to read-only mode.");
    }
    return false;
  }

  deriveAccess = async (): Promise<Access> => {
    const isAdmin = this.keycloak.hasRealmRole(FA_USER_REALM_ROLE);
    const access: Access = {
      sell: false,
      buy: false,
      withdraw: false,
      deposit: false,
      impersonate: true,
      cancelOrder: false,
      switch: false,
      advisor: false,
    };
    if (isAdmin) {
      access.impersonate = true;
      return access;
    }
    access.buy = await this.hasAnyRole("enableBuy");
    access.sell = await this.hasAnyRole("enableSell");
    access.deposit = await this.hasAnyRole("enableDeposit");
    access.withdraw = await this.hasAnyRole("enableWithdraw");
    access.impersonate = await this.hasAnyRole("enableImpersonate");
    access.cancelOrder = await this.hasAnyRole("enableCancelOrder");
    access.switch = await this.hasAnyRole("enableSwitch");
    access.advisor = await this.hasAnyRole("enableAdvisor");
    if (Object.values(access).every((value) => value === false)) {
      throw new Error("User does not have a required role. Revoking access.");
    }
    return access;
  };

  /**
   * Overrides the user's linked contact in the keycloak state.
   * Useful to impersonate another contact in the app.
   * @param id the database id of the contact to set.
   */
  setLinkedContact = (id: string) => {
    if (this.state.authenticated) {
      this.state = {
        ...this.state,
        linkedContact: id,
      };
    }
    this.updateState();
  };

  private resetInactivityTimer = () => {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.countdownSeconds = 300;
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.inactivityTimer = window.setTimeout(() => {
      this.handleInactivity();
    }, this.inactivityTimeout);
    this.startCountdown();
  };

  private startCountdown() {
    this.countdownInterval = window.setInterval(() => {
      if (this.countdownSeconds > 0) {
        this.countdownSeconds -= 1;
        // console.log(`Logout in: ${this.countdownSeconds} seconds due to inactivity`);
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }

  private handleInactivity() {
    this.onAuthLogout();
  }

  private setupInactivityListeners() {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    events.forEach((event) => window.addEventListener(event, this.resetInactivityTimer));
    this.resetInactivityTimer();
  }

  private removeInactivityListeners() {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    events.forEach((event) => window.removeEventListener(event, this.resetInactivityTimer));
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  destroy() {
    this.removeInactivityListeners();
  }
}

export const keycloakService = new KeycloakService(
  new Keycloak(`${process.env.PUBLIC_URL}/keycloak.json`)
);